<!--
 * @Author: LazyQ
 * @Date: 2020-04-13 13:21:26
 * @LastEditTime: 2020-04-21 10:24:16
 * @LastEditors: LazyQ
 -->
<style lang="less" scoped>
.model-action {
  height: 46px; 
  line-height: 36px; 
  width: 300px
}
</style>
<style>
.ant-table-tbody {
  /* font-size: 10px !important; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: rgba(51, 51, 51, 1) !important;
}
.ant-table-row {
  height: 37px !important;
}
</style>

<template>
  <span>
    <a-mentions class="model-action" placeholder="请选择客户" readonly v-model="customer_name" @click="selectCustomer"></a-mentions>
    <a-modal
      title="客户列表"
      ok-text="确认"
      cancel-text="取消"
      :maskClosable="false"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-input-search
        placeholder="请输入关键词"
        style="width: 218px"
        @search="onSearch"
      />
      <a-table
        :row-selection="{ type: 'radio', selectedRowKeys: selectedRowKeys, onChange: onSelectChange, onSelect: onSelect }"
        :columns="columns"
        :loading="loading"
        :dataSource="data"
        :pagination="{
          current, 
          total,
          pageSize: size, 
        }"
        @change="tabChange"
        style="margin-top: 13px"
      >
      </a-table>
    </a-modal>  
  </span>
</template>

<script>
import { fetchCustomerlist } from "@/api/customer" 

const columns = [
  {
    title: "客户名称",
    dataIndex: "customer_name",
  },
  {
    title: "客户简称",
    dataIndex: "abbreviated_name",
  },
  {
    title: "客户全称",
    dataIndex: "full_name",
  }
];

export default {
  props: {
    customer_name: {
      type: String,
      default: undefined
    },
    customer_id: undefined,
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      loading: false,
      selectedRowKeys: [],
      data: [],
      columns,
      total: 10,
      size: 10,
      current: 1,
      search: undefined,
    };
  },
  created() {
  },
  methods: {
    async getCustomerlist() {
      const that = this;
      this.loading = true;
      try {
        let res = await fetchCustomerlist({
          page_size: this.size,
          page: this.current,
          search_value: this.search,
        });
        if (!res) return;
        this.loading = false;
        this.total = res.total;
        this.data = res.customer_list.map((item, index) => {
          if (item.abbreviated_name.length <= 0) {
            item.abbreviated_name = "-";
          }
          item["key"] = item.customer_id;
          return item;
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    tabChange(pagination, filters, sorter, { currentDataSource }) {
      let { current, pageSize } = pagination;
      this.current = current;
      this.size = pageSize;
      this.getCustomerlist();
    },
    onSearch(e) {
      this.search = e;
      this.current = 1;
      this.getCustomerlist();
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onSelect(record) {
      this.$emit('update:customer_name', record.customer_name);
      this.$emit('update:customer_id', record.customer_id);
    },
    /// 选择客户
    selectCustomer() {
      this.visible = true;
      this.getCustomerlist();
      this.selectedRowKeys = [this.customer_id];
    },
    handleOk(e) {
      if (this.selectedRowKeys.length > 0) {
        this.$emit('selectedHandler', this.selectedRowKeys[0])        
        this.$emit('update:customer_id', this.selectedRowKeys[0]);
        this.visible = false;
      }
    },
    handleCancel(e) {
      if (this.customer_id == undefined) {
        this.selectedRowKeys = [];
        this.$emit('update:customer_name', undefined);
        this.$emit('update:customer_id', undefined);
      }
      this.visible = false;
    },
  }
};
</script>